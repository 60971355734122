const $carousel = document.getElementById("carousel-vertical");
const $track = document.getElementById("track-vertical");
const $slides = document.querySelectorAll(".slide-vertical");
const $before = document.getElementById("before-vertical");
const $lastSlide = $slides.item($slides.length - 1);

const INTERVAL = 2000;
const DURATION = "duration-1000";
const IS_FIREFOX = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

let moveWidth = $before.offsetWidth;
let moveHeight = $carousel.offsetHeight;

let prevTimestamp;
let activeIndex = 0;
let slidesCount = $slides.length;
let movedStart = false;

if (!IS_FIREFOX) {
  $slides.forEach(function (slide) {
    slide.classList.add(
      "bg-gradient-to-b",
      "from-main-green",
      "to-secondary-green",
      "bg-clip-text",
      "text-transparent",
    );
    slide.classList.remove("text-secondary-green");
  });
}

function tick(timestamp) {
  if (!prevTimestamp) {
    prevTimestamp = timestamp;
    const movedHeight = activeIndex * -moveHeight;
    $track.style.cssText = `transform: translate3d(${moveWidth}px, ${movedHeight}px, 0px)`;
  }
  if (timestamp - prevTimestamp > INTERVAL) {
    moveTrack();
    prevTimestamp = timestamp;
  }
  requestAnimationFrame(tick);
}

function moveTrack() {
  activeIndex += 1;
  const movedHeight = activeIndex * -moveHeight;
  $track.classList.toggle(DURATION, true);
  $track.style.cssText = `transform: translate3d(${moveWidth}px, ${movedHeight}px, 0px)`;

  fillSlides();
  if (-movedHeight / moveHeight >= slidesCount) {
    resetTrack();
  }
}

function resetTrack() {
  activeIndex = -1;
  const movedHeight = activeIndex * -moveHeight;
  $track.classList.toggle(DURATION, false);
  $track.style.cssText = `transform: translate3d(${moveWidth}px, ${movedHeight}px, 0px)`;
  fillSlides();
  moveTrack();
}

function fillSlides() {
  const movedHeight = activeIndex * -moveHeight;
  if (movedHeight >= 0) {
    $lastSlide.style.cssText = `transform: translate3d(0px, -${$track.offsetHeight}px, 0px)`;
    movedStart = true;
  } else {
    $lastSlide.style.cssText = null;
    movedStart = false;
  }
}

requestAnimationFrame(tick);

window.addEventListener("resize", function () {
  moveWidth = $before.offsetWidth;
  moveHeight = $carousel.offsetHeight;
});

document.fonts.ready.then(function () {
  moveWidth = $before.offsetWidth;
  moveHeight = $carousel.offsetHeight;
});
